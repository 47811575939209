<template>
  <div class="bottom-block">
    <hr>
    <el-button type="plain" class="small" @click="$router.go(-1)">
      {{ $t('back') }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'BottomBackBlock'
}
</script>
