<template>
  <!-- Example -->
  <div id="example" :class="{'example-visible' : visible}">
    <div class="card-wrapper">
      <div class="inner-wrapper">
        <div class="top-declaration">
          Guide
        </div>
        <button
          type="button"
          aria-label="Close"
          class="el-dialog__headerbtn"
          @click="$emit('close')"
        >
          <i class="el-dialog__close el-icon el-icon-close" />
        </button>

        <!-- Content -->
        <div v-if="guideExample" class="position-relative mt-4">

          <!-- Shadow -->
          <div v-if="!expanded" class="bottom-shadow" />
          <!-- /Shadow -->

          <div :class="{'guide-clamped': !expanded}">
            <div class="text-center mb-2 mb-md-0">
              <img
                class="content-img img-rounded"
                :src="getImgUrl()"
              >
            </div>
            <!-- Example Text -->
            <h2 class="text-center text-md-left mb-3 mt-0">
              {{ guideExample.title }}
            </h2>
            <p class="m-0" v-html="guideExample.text" />
            <!-- /Example Text -->
          </div>
        </div>
        <!-- /Content -->

        <template v-if="!expand">
          <!-- Lear More & Show Less -->
          <a class="m-0" @click="expandElement">
            <!-- See More -->
            <span :class="{'visible': !expanded}" class="more animation-pulse text-uppercase">{{ $t('seeMore') }} +</span>
            <!-- /See More -->
          </a>
          <!-- /Lear More & Show Less -->
        </template>

        <div v-if="expanded" class="d-md-none">
          <!-- Divider -->
          <ib-divider block class="my-3" />
          <!-- /Divider -->

          <div class="d-flex justify-content-end">
            <ib-button
              class="text-uppercase"
              submit-button
              @click="onClose"
            >
              {{ $t('close') }}
            </ib-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Example -->
</template>

<script>
import defaultGuideExampleImages from '@/data/defaultGuideExampleImages.json'

import { mapGetters } from 'vuex'

export default {
  name: 'GuideExample',

  props: {
    visible: {
      default: false,
      type: Boolean
    },
    type: {
      required: true,
      type: String
    },
    expand: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      expanded: false,
      defaultGuideExampleImages
    }
  },

  computed: {
    ...mapGetters('idea', ['getGuideExample']),

    guideExample () {
      return this.getGuideExample(this.type)
    }
  },

  mounted () {
    if (this.expand) {
      this.expanded = true
    }
  },

  methods: {
    expandElement () {
      this.expanded = !this.expanded
      this.headerScrollable(this.expanded)
    },

    headerScrollable (visible) {
      const header = document.querySelector('.header-fixed')
      // const fakeDiv = document.querySelector('.height-holder')
      if (header) {
        setTimeout(function () {
          const h = header.offsetHeight
          if (visible && h > window.innerHeight) {
            header.classList.add('scrollable')
          } else {
            header.classList.remove('scrollable')
            // fakeDiv.style.height = h + 'px'
          }
        }, 350)
      }
    },

    getImgUrl () {
      if (this.guideExample && this.guideExample.image) {
        return `${this.guideExample.image.path}${this.guideExample.image.filename}`
      }

      return require(`@/assets/img/illustrations/guide-examples/${this.defaultGuideExampleImages[this.type]}`)
    },

    onClose () {
      const content = document.querySelector('.content')
      if (content) {
        content.scrollIntoView()
      }

      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.more {
  color: #0291ff;
}

.content-img {
  width: 90%;

  @include media-breakpoint-up($md) {
    float: right;
    width: 35%;
    margin: 10px 20px 20px;
  }
}

.bottom-shadow {
  -webkit-box-shadow: inset 0 -25px 10px -15px rgba(255, 255, 255, 1);
  -moz-box-shadow: inset 0 -25px 10px -15px rgba(255, 255, 255, 1);
  box-shadow: inset 0 -25px 10px -15px rgba(255, 255, 255, 1);
  position: absolute;
  width: 100%;
  height: 100%;

  @include media-breakpoint-up($md) {
    width: 62%;
  }
}

.img-rounded {
  border-radius: 0.75rem;
}

.guide-clamped {
  overflow: hidden;
  max-height: 20.5rem;
  line-height: 1.6;

  @include media-breakpoint-up($md) {
    max-height: 17.5rem;
  }
}
</style>
