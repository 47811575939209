<template>
  <!-- Dialog Remove Note -->
  <ib-dialog
    :visible="visible"
    :show-close="false"
    width="550px"
    top="8vh"
    empty-header
    @close="hideDialog"
  >
    <!-- Dialog Content -->
    <ib-dialog-confirmation-content
      :image="require('@/assets/img/illustrations/trash.svg')"
      :title="$t('dialogs.remove.confirmDeletion')"
      :subtitle="$t('dialogs.remove.areYouSureYouWantToDeleteIt')"
      :show-illustration="false"
    />
    <!-- /Dialog Content -->

    <!-- Dialog Footer -->
    <template #footer>
      <ib-divider block class="mb-4" />
      <ib-dialog-footer
        button-size="md"
        :submit-btn-text="$t('dialogs.remove.yesDeleteIt')"
        :cancel-btn-text="$t('dialogs.remove.noJustKidding')"
        :loading="loading"
        space-between-buttons
        variant-button="red"
        @cancel="hideDialog"
        @submit="$emit('yes')"
      />
    </template>
    <!-- /Dialog Footer -->

  </ib-dialog>
  <!-- /Dialog Remove Note -->

</template>

<script>
export default {
  name: 'DialogRemoveNote',

  props: {
    visible: {
      default: false,
      type: Boolean
    },

    loading: {
      default: false,
      type: Boolean
    }
  },

  methods: {
    hideDialog () {
      this.$emit('no')
    }
  }
}
</script>
