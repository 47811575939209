<template>
  <div class="delete-note-handwriting d-none d-md-flex">
    <ib-handwriting :text="$t('handwriting.clickHereToDeleteAllTemplateItems')">
      <template #arrow>
        <img :src="require('@/assets/img/util/arrow-right.svg')" width="80" alt="arrow">
      </template>
    </ib-handwriting>
  </div>
</template>

<script>
import IbHandwriting from '@/components/_v2/IbHandwriting'

export default {
  name: 'DeleteTemplateNoteHandwriting',

  components: {
    IbHandwriting
  }
}
</script>

<style scoped lang="scss">
.delete-note-handwriting {
  ::v-deep {
    .ib-handwriting-text {
      margin-top: 15px !important;
      font-size: 18px;

      @include media-breakpoint-up($lg) {
        font-size: 22px;
      }
    }
  }
}
</style>
