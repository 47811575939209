<template>
  <div id="dialog-delete-plan">
    <el-dialog
      :visible.sync="dialogDeletePlanVisible"
      top="0"
    >
      <div class="dialog-body">
        <div class="illustration-wrapper">
          <img src="@/assets/img/icons/idea-plan/eraser-icon.png" alt="">
        </div>
        <h1>You want to wipe it off?</h1>
        <p class="text-lead">
          All of your data from Idea Plan will be erased
        </p>
      </div>
      <div class="dialog-footer">
        <hr>
        <div class="flex is-justify-space-between">
          <div>
            <a href="">Cancel</a>
            <el-button type="default" class="small">
              Erase
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DialogDeletePlan',
  props: {},
  data () {
    return {
      dialogDeletePlanVisible: false
    }
  },
  created () {},
  methods: {
    showDeletePlan () {
      this.dialogDeletePlanVisible = true
    }
  }
}
</script>

<style scoped>

</style>
