<template>
  <div
    class="ib-handwriting-wrapper d-flex align-items-center"
    :class="{'flex-row-reverse': reverse}"
  >
    <component :is="tag" class="ib-handwriting-text font-tragic-marker text-primary-color">
      {{ text }}
    </component>
    <slot name="arrow" />
  </div>
</template>

<script>
export default {
  name: 'IbHandwriting',

  props: {
    text: {
      type: String,
      required: true
    },

    tag: {
      type: String,
      default: 'h3'
    },

    reverse: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.ib-handwriting-wrapper {
  column-gap: 15px;

  .ib-handwriting-text {
    margin: 0;
  }
}
</style>
