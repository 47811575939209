<template>
  <div v-if="data.length" class="position-relative">
    <!-- Add note handwriting -->
    <add-note-handwriting v-if="canEdit && isIdeaPlanEmpty && !isIdeaFromTemplate" />
    <!-- /Add note handwriting -->
    <div ref="tmp" class="masonry d-none d-md-flex">
      <el-row type="flex">
        <el-col :span="12" class="items__vertical items--inner3">
          <div class="items__side items__side--top items__horizontal items--inner2">
            <item :data="data[6]" @dialog-add-show="dialogAddNote" @dialog-edit-note="dialogEditNote" @dialog-remove-note="dialogRemoveNote" />
            <item :data="data[3]" @dialog-add-show="dialogAddNote" @dialog-edit-note="dialogEditNote" @dialog-remove-note="dialogRemoveNote" />
          </div>
          <div class="items__side items__side--middle items__horizontal items--inner2">
            <item :data="data[8]" @dialog-add-show="dialogAddNote" @dialog-edit-note="dialogEditNote" @dialog-remove-note="dialogRemoveNote" />
            <item :data="data[4]" @dialog-add-show="dialogAddNote" @dialog-edit-note="dialogEditNote" @dialog-remove-note="dialogRemoveNote" />
          </div>
          <div class="items__side items__side--bottom items__horizontal items--inner2">
            <item :data="data[11]" @dialog-add-show="dialogAddNote" @dialog-edit-note="dialogEditNote" @dialog-remove-note="dialogRemoveNote" />
            <item :data="data[5]" @dialog-add-show="dialogAddNote" @dialog-edit-note="dialogEditNote" @dialog-remove-note="dialogRemoveNote" />
          </div>
        </el-col>

        <el-col :span="12" :pull="6" class="items__center items__vertical items--inner2">
          <div class="items__horizontal items--inner2">
            <item :data="data[0]" @dialog-add-show="dialogAddNote" @dialog-edit-note="dialogEditNote" @dialog-remove-note="dialogRemoveNote" />
            <item :data="data[1]" @dialog-add-show="dialogAddNote" @dialog-edit-note="dialogEditNote" @dialog-remove-note="dialogRemoveNote" />
          </div>
          <div class="items__horizontal items--inner2">
            <item :data="data[7]" @dialog-add-show="dialogAddNote" @dialog-edit-note="dialogEditNote" @dialog-remove-note="dialogRemoveNote" />
            <item :data="data[2]" @dialog-add-show="dialogAddNote" @dialog-edit-note="dialogEditNote" @dialog-remove-note="dialogRemoveNote" />
          </div>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col class="items__bottom items__horizontal items--inner4">
          <item :data="data[9]" @dialog-add-show="dialogAddNote" @dialog-edit-note="dialogEditNote" @dialog-remove-note="dialogRemoveNote" />
          <item :data="data[13]" @dialog-add-show="dialogAddNote" @dialog-edit-note="dialogEditNote" @dialog-remove-note="dialogRemoveNote" />
          <item :data="data[12]" @dialog-add-show="dialogAddNote" @dialog-edit-note="dialogEditNote" @dialog-remove-note="dialogRemoveNote" />
          <item :data="data[10]" @dialog-add-show="dialogAddNote" @dialog-edit-note="dialogEditNote" @dialog-remove-note="dialogRemoveNote" />
        </el-col>
      </el-row>
    </div>

    <div class="d-md-none mb-5">
      <el-collapse>
        <el-collapse-item v-for="section in data" :key="section.id" class="item">
          <template #title>
            <img
              class="ml-1"
              :src="baseUrl + section.icon"
              :alt="section.title"
            >
            <p>
              {{ $t(`pages.ideaPlan.sections.${section.title}`) }}
            </p>
          </template>
          <div
            v-for="note in section.notes"
            :key="note.id"
            class="note mt-2"
            :class="note.color"
            @click="dialogEditNote(section, note)"
          >
            <div class="d-flex justify-content-between mb-2">
              <h4 class="mb-0 mt-1">
                {{ note.title }}
              </h4>
              <i
                v-if="canEdit"
                class="el-icon el-icon-close o-50 h4"
                @click.stop="dialogRemoveNote(note)"
              />
            </div>
            <div v-html="note.text" />
          </div>
          <div
            v-if="section.notes && section.notes.length === 0"
            class="placeholder-text o-50"
            v-html="$t(`pages.ideaPlan.sectionsDescription.${section.title}`)"
          />
          <div class="text-center mt-3">
            <el-button
              v-if="canEdit"
              class="item-add-note-button"
              type="plus-round-xs"
              icon="el-icon-plus"
              @click="dialogAddNote(section)"
            />
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <!-- Dialog Add Edit Note -->
    <dialog-idea-plan-note-add-edit
      v-if="dialogAddEditNoteVisible"
      :id="id"
      :text="noteText"
      :title="noteTitle"
      :color="noteColor"
      :placeholder-title="placeholderTitle"
      :placeholder-text="placeholderText"
      :section-id="sectionId"
      :dialog-add-edit-note-visible="dialogAddEditNoteVisible"
      :filled-sections="filledSections"
      @close-dialog="closeAddEditNoteDialog"
    />
    <!--/ Dialog Add Edit Note -->

    <!-- Dialog Remove Note -->
    <dialog-remove-note
      v-if="dialogRemoveNoteVisible"
      :visible="dialogRemoveNoteVisible"
      :loading="removeNoteLoading"
      @no="cancelRemoveNote"
      @yes="removeNote"
    />
    <!-- /Dialog Remove Note -->
  </div>
</template>
<script>
import AddNoteHandwriting from '@/views/Home/Components/AddNoteHandwriting'
import DialogIdeaPlanNoteAddEdit from '@/views/Home/IdeaPlan/Dialogs/DialogIdeaPlanNoteAddEdit'
import DialogRemoveNote from '@/views/Home/IdeaPlan/Dialogs/DialogRemoveNote'
import Item from '@/views/Home/IdeaPlan/Section/Item'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'IdeaPlanLayout',
  components: {
    AddNoteHandwriting,
    Item,
    DialogIdeaPlanNoteAddEdit,
    DialogRemoveNote
  },

  mixins: [
    MixinIdeaRoles
  ],

  props: {
    data: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      dialogAddEditNoteVisible: false,
      noteText: '',
      noteTitle: '',
      placeholderTitle: '',
      placeholderText: '',
      sectionId: 0,
      id: 0,
      noteColor: '',
      removeNoteId: null,
      dialogRemoveNoteVisible: false,
      removeNoteLoading: false
    }
  },

  computed: {
    ...mapGetters('idea', ['isIdeaFromTemplate']),
    ...mapGetters('idea/ideaPlan', ['isIdeaPlanEmpty']),

    filledSections () {
      const filteredObj = this.data.filter(
        item => typeof item.notes === 'object'
      )

      return filteredObj.length
    }
  },

  watch: {
    data: function () {
      const safari = navigator.userAgent.indexOf('Safari') > -1
      const chrome = navigator.userAgent.indexOf('Chrome') > -1

      if (safari === true && chrome === false && this.data.length > 0) {
        this.$nextTick(function () {
          this.$refs.tmp.classList.add('diff')
        })
      }
    }
  },

  mounted () {
    const safari = navigator.userAgent.indexOf('Safari') > -1
    const chrome = navigator.userAgent.indexOf('Chrome') > -1

    if (safari === true && chrome === false && this.data.length > 0) {
      this.$nextTick(function () {
        this.$refs.tmp.classList.add('diff')
      })
    }
  },

  methods: {
    ...mapActions('idea/ideaPlan', [
      'deleteNote'
    ]),

    dialogAddNote (sectionData) {
      if (this.canEdit) {
        this.id = 0
        this.noteText = ''
        this.noteTitle = ''
        this.noteColor = ''
        this.placeholderTitle = sectionData.title
        this.placeholderText = sectionData.text
        this.sectionId = sectionData.id
        this.dialogAddEditNoteVisible = true
      }
    },

    closeAddEditNoteDialog () {
      this.dialogAddEditNoteVisible = false
      this.$notify.closeAll()
    },

    showDialogPlanNote () {
      this.dialogAddEditNoteVisible = true
    },

    dialogEditNote (sectionData, note) {
      if (this.canEdit) {
        this.id = note.id
        this.noteText = note.text
        this.noteTitle = note.title
        this.noteColor = note.color ? note.color : ''
        this.sectionId = sectionData.id
        this.placeholderTitle = sectionData.title
        this.placeholderText = sectionData.text

        this.dialogAddEditNoteVisible = true
      }
    },

    dialogRemoveNote (note) {
      this.dialogRemoveNoteVisible = true
      this.removeNoteId = note.id
    },

    cancelRemoveNote () {
      this.dialogRemoveNoteVisible = false
      this.removeNoteId = null
    },

    removeNote () {
      this.removeNoteLoading = true
      this.deleteNote(this.removeNoteId)
        .then(_ => {
          this.dialogRemoveNoteVisible = false
          this.removeNoteLoading = false
          this.removeNoteId = null
        })
    }
  }
}
</script>
