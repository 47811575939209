<template>
  <div class="add-note-handwriting d-none d-md-flex position-absolute">
    <ib-handwriting
      :text="$t('handwriting.clickHereToAddYourFirstNote')"
      reverse
    >
      <template #arrow>
        <img
          class="mt-3"
          :src="require('@/assets/img/util/arrow-left.svg')"
          width="45"
          alt="arrow"
        >
      </template>
    </ib-handwriting>
  </div>
</template>

<script>
import IbHandwriting from '@/components/_v2/IbHandwriting'

export default {
  name: 'AddNoteHandwriting',

  components: {
    IbHandwriting
  }
}
</script>

<style scoped lang="scss">
.add-note-handwriting {
  z-index: 1;
  left: 40%;
  top: -7%;
}
</style>
